var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Advert Number","label-for":"advert-number"}},[_c('b-form-input',{attrs:{"id":"advert-number","placeholder":"Advert Number"},model:{value:(_vm.job_number),callback:function ($$v) {_vm.job_number=$$v},expression:"job_number"}})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Post Name","label-for":"post-name"}},[_c('b-form-input',{attrs:{"id":"post-name","placeholder":"Post Name"},model:{value:(_vm.post),callback:function ($$v) {_vm.post=$$v},expression:"post"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Department Name","label-for":"department-name"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.departments},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Positions","label-for":"positions"}},[_c('b-form-input',{attrs:{"id":"positions","placeholder":"Positions"},model:{value:(_vm.positions),callback:function ($$v) {_vm.positions=$$v},expression:"positions"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('small',[_vm._v("Opening Date")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"opening_date","placeholder":"Opening date","config":{
              altInput: true,
              altFormat: 'F j, Y',
              dateFormat: 'Y-m-d',
            }},model:{value:(_vm.opening_date),callback:function ($$v) {_vm.opening_date=$$v},expression:"opening_date"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('small',[_vm._v("Closing Date")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"closing_date","placeholder":"Closing date","config":{
              altInput: true,
              altFormat: 'F j, Y',
              dateFormat: 'Y-m-d',
            }},model:{value:(_vm.closing_date),callback:function ($$v) {_vm.closing_date=$$v},expression:"closing_date"}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Minimum Certificate Required (optional)","label-for":"minimum-cert"}},[_c('v-select',{attrs:{"id":"minimum-cert","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.certificates},model:{value:(_vm.certificate),callback:function ($$v) {_vm.certificate=$$v},expression:"certificate"}})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Job Group","label-for":"job-group"}},[_c('v-select',{attrs:{"id":"job-group","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.jobGroupOptions},model:{value:(_vm.job_group),callback:function ($$v) {_vm.job_group=$$v},expression:"job_group"}})],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" Description ")])]),_c('b-row',[_c('el-tiptap',{attrs:{"extensions":_vm.extensions},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"block":"","variant":"primary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.saveJob.apply(null, arguments)}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),(!_vm.loading)?_c('span',[_vm._v(" Save Job")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }