<template>
  <div>
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Advert Number"
              label-for="advert-number"
            >
              <b-form-input
                id="advert-number"
                v-model="job_number"
                placeholder="Advert Number"
              />
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group
              label="Post Name"
              label-for="post-name"
            >
              <b-form-input
                id="post-name"
                v-model="post"
                placeholder="Post Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Department Name"
              label-for="department-name"
            >
              <v-select
                v-model="department"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="departments"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Positions"
              label-for="positions"
            >
              <b-form-input
                id="positions"
                v-model="positions"
                placeholder="Positions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <small>Opening Date</small>
            <flat-pickr
              v-model="opening_date"
              class="form-control"
              name="opening_date"
              placeholder="Opening date"
              :config="{
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
              }"
            />
          </b-col>
          <b-col md="6">
            <small>Closing Date</small>
            <flat-pickr
              v-model="closing_date"
              class="form-control"
              name="closing_date"
              placeholder="Closing date"
              :config="{
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
              }"
            />
          </b-col>
          <!-- minimum cert -->
          <b-col
            md="6"
            class="mt-2"
          >
            <b-form-group
              label="Minimum Certificate Required (optional)"
              label-for="minimum-cert"
            >
              <v-select
                id="minimum-cert"
                v-model="certificate"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="certificates"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mt-2"
          >
            <b-form-group
              label="Job Group"
              label-for="job-group"
            >
              <v-select
                id="job-group"
                v-model="job_group"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="jobGroupOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="divider my-2">
          <div class="divider-text">
            Description
          </div>
        </div>
        <b-row>
          <el-tiptap
            v-model="description"
            :extensions="extensions"
          />
        </b-row>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
          class="mt-2"
          :disabled="loading"
          @click.prevent="saveJob"
        >
          <b-spinner
            v-if="loading"
            small
            type="grow"
          />
          <span v-if="!loading"> Save Job</span>
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  BButton,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  // text extensions
  Bold,
  Underline,
  Italic,
  Strike,
  Code,
  FontType,
  FontSize,
  TextColor,
  TextHighlight,
  FormatClear,
  // paragraph extensions
  Heading,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  TextAlign,
  LineHeight,
  Indent,
  Blockquote,
  CodeBlock,
  // rich and tools extensions
  Link,
  Image,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Iframe,
  TrailingNode,
  HorizontalRule,
  Print,
  Preview,
  SelectAll,
  History,
} from 'element-tiptap'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

import { formatDate } from '@core/utils/filter'

import educationLevels from '@/views/profile/educationLevels.json'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    job: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      jobGroupOptions: [
        'N/A',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'J',
        'K',
        'L',
        'M',
        'N',
        'P',
        'Q',
        'R',
        'S',
        'T',
      ],
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Code(),
        new FontType(),
        new FontSize(),
        new TextColor({ bubble: true }),
        new TextHighlight({ bubble: true }),
        new FormatClear(),
        new History(),
        new Heading({ level: 5 }),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new TextAlign(),
        new LineHeight(),
        new Indent(),
        new Blockquote(),
        new CodeBlock(),
        new Link(),
        new Image(),
        new Iframe(),
        new Table({ resizable: true }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new HorizontalRule(),
        new Print(),
        new Preview(),
        new SelectAll(),
        new TrailingNode(),
      ],

      loading: false,
      id: null,
      job_number: null,
      post: null,
      department: null,
      positions: null,
      opening_date: null,
      closing_date: null,
      certificate: null,
      job_group: null,
      description: '<p><b>Requirements 💼 ...</b></p>',
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user || {}
    },
    certificates() {
      let certificates = []
      educationLevels.forEach(row => {
        certificates = certificates.concat(row.examinations)
      })
      return certificates
    },
    timeRemaining() {
      const date = moment(this.job.closing_date)
      return moment().diff(date, 'days')
    },
    departments() {
      return this.$store.state.departments.departments || []
    },
  },
  created() {
    if (this.job) {
      const {
        id,
        number,
        post,
        department,
        positions,
        opening_date,
        closing_date,
        certificate,
        job_group,
        description,
      } = this.job

      this.id = id
      this.job_number = number
      this.post = post
      this.department = this.departments.find(e => e.id === department) || null
      this.positions = positions
      // eslint-disable-next-line camelcase
      this.opening_date = opening_date
      // eslint-disable-next-line camelcase
      this.closing_date = closing_date
      this.certificate = certificate
      // eslint-disable-next-line camelcase
      this.job_group = job_group
      this.description = description
    }
  },
  beforeDestroy() {},
  methods: {
    formatDate,
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    findDepartment(id) {
      const result = this.departments.find(e => e.id === id)
      return result ? result.name : 'Unknown'
    },
    saveJob() {
      if (!this.id) {
        this.loading = true
        this.$store
          .dispatch('jobs/addJob', {
            number: this.job_number,
            post: this.post,
            department: this.department.id,
            positions: this.positions,
            opening_date: this.opening_date,
            closing_date: this.closing_date,
            certificate: this.certificate.name,
            job_group: this.job_group,
            description: this.description,
          })
          .then(() => {
            this.showToast(
              'Success',
              `Job ${this.job_number} successfully posted`,
              'Check',
              'success',
            )
            if (this.$router.go(-1)) {
              this.$router.go(-1)
            } else {
              this.$router.push({
                name: 'jobs-details',
                params: this.$route.params,
              })
            }
          })
          .catch(err => this.handleErrors(err))
      } else {
        this.loading = true
        this.$store
          .dispatch('jobs/updateJob', {
            id: this.id,
            job: {
              number: this.job_number,
              post: this.post,
              department: this.department.id,
              positions: this.positions,
              opening_date: this.opening_date,
              closing_date: this.closing_date,
              certificate: this.certificate.name,
              job_group: this.job_group,
              description: this.description,
            },
          })
          .then(() => {
            this.showToast(
              'Success',
              `Job ${this.job_number} successfully posted`,
              'Check',
              'success',
            )
            if (this.$router.go(-1)) {
              this.$router.go(-1)
            } else {
              this.$router.push({
                name: 'jobs-details',
                params: this.$route.params,
              })
            }
          })
          .catch(err => this.handleErrors(err))
      }
    },
    handleErrors(err) {
      this.loading = false
      if (err.response) {
        if ('data' in err.response) {
          if ('data' in err.response.data) {
            if (Array.isArray(err.response.data.data)) {
              err.response.data.data.forEach(param => {
                this.showToast(
                  `Error ${err.response.status}`,
                  param.message,
                  'X',
                  'danger',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
              })
            }
          } else {
            this.showToast(
              `Error ${err.response.status}`,
              `${err.response.data.message}`,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              15000,
            )
          }
        } else {
          this.showToast(
            `Error ${err.response.status}`,
            `${err.response.statusText}`,
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
          // eslint-disable-next-line function-paren-newline
        }
      } else {
        this.showToast(
          'Error',
          `${err.message}`,
          'X',
          'danger',
          // eslint-disable-next-line comma-dangle
          15000,
        )
        // eslint-disable-next-line function-paren-newline
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
