<template>
  <job-form :job="job" />
</template>

<script>
import JobForm from './JobForm.vue'

export default {
  components: {
    JobForm,
  },
  data() {
    return {
      job: null,
    }
  },
  created() {
    if ('id' in this.$route.params) {
      // This is an update
      const job = this.$store.state.jobs.jobs.find(
        e => e.id === this.$route.params.id,
      )
      this.job = job
    }
  },
}
</script>

<style></style>
